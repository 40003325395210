import React, {useState} from 'react';
import './App.scss';
import NavBar from './NavBar/NavBar';
import WorkInProgress from './pages/WorkInProgress';
import {Route, Routes} from 'react-router-dom'
import NotFound from './pages/NotFound';
import Home from './pages/Home/Home';
import AboutMe from './pages/AboutMe/AboutMe';
import Contact from './pages/Contact/Contact';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';


export enum Page {
    HOME = 'HOME',
    ABOUT_ME = 'ABOUT_ME',
    PROJECTS = 'PROJECTS',
    CONTACT = 'CONTACT'
}

export const pageInformation = {
    [Page.HOME]: {
        link: '/',
        text: 'Home'
    },
    [Page.ABOUT_ME]: {
        link: '/about',
        text: 'About me'
    },
    [Page.PROJECTS]: {
        link: '/projects',
        text: 'Projects'
    },
    [Page.CONTACT]: {
        link: '/contact',
        text: 'Contact'
    }
}

function App(): JSX.Element {
    const [currentPage, setCurrentPage] = useState(Page.HOME)


    return (
        <div className="App">
            <header>
                <NavBar currentPage={currentPage} setCurrentPage={setCurrentPage}/>

                <ToastContainer position={toast.POSITION.TOP_RIGHT}
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="dark"/>
            </header>
            <main className="App-main">
                <Routes>
                    <Route path={pageInformation[Page.HOME].link} element={<Home/>}/>
                    <Route path={pageInformation[Page.ABOUT_ME].link} element={<AboutMe/>}/>
                    <Route path={pageInformation[Page.PROJECTS].link} element={<WorkInProgress/>}/>
                    <Route path={pageInformation[Page.CONTACT].link} element={<Contact/>}/>
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
            </main>
        </div>
    );
}

export default App;
