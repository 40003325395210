import React, {ChangeEvent, FocusEvent, FormEvent, useRef, useState} from 'react';
import './Contact.scss';
import {Button, Input, Textarea} from '@nextui-org/react';
import ReCAPTCHA from 'react-google-recaptcha';
import {FormElement} from '@nextui-org/react/types/input/input-props';
import {toast} from 'react-toastify';

interface FormValues {
    name?: string,
    email?: string,
    message?: string,
    captcha?: string | null
}

function Contact() {
    const recaptchaRef = useRef<ReCAPTCHA>(null);


    const [contactFormValues, setContactFormValues] = useState({
        name: '',
        email: '',
        message: '',
        captcha: ''
    } as FormValues)

    const [contactFormTouched, setcontactFormTouched] = useState({
        name: false,
        email: false,
        message: false
    })

    const [contactFormErrors, setContactFormErrors] = useState({
        name: {valid: false, error: 'Required.'},
        email: {valid: false, error: 'Required.'},
        message: {valid: false, error: 'Required.'}
    })

    function handleFormChange(event: ChangeEvent<FormElement>) {
        setContactFormValues({...contactFormValues, [event.target.name]: event.target.value});
    }

    function handleFormBlur(event: FocusEvent<FormElement>) {
        setcontactFormTouched({...contactFormTouched, [event.target.name]: true});
        validateForm();
    }

    function handleRecaptchaChange(token: string | null) {
        setContactFormValues({...contactFormValues, 'captcha': token});
    }

    function validateForm() {
        if (!contactFormValues.name)
            contactFormErrors.name = {valid: false, error: 'Required.'}
        else
            contactFormErrors.name = {valid: true, error: ''}

        if (!contactFormValues.email)
            contactFormErrors.email = {valid: false, error: 'Required.'}
        else if (!validateEmail(contactFormValues.email))
            contactFormErrors.email = {valid: false, error: 'This is not a valid email address.'}
        else
            contactFormErrors.email = {valid: true, error: ''}

        if (!contactFormValues.message)
            contactFormErrors.message = {valid: false, error: 'Required.'}
        else
            contactFormErrors.message = {valid: true, error: ''}
    }

    function validateEmail(email: string) {
        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regexp.test(email);
    }

    function getErrorMessage(field: keyof typeof contactFormTouched): string {
        return contactFormTouched[field] && !contactFormErrors[field].valid ? contactFormErrors[field].error : ''
    }

    function resetForm() {
        setContactFormValues({
            name: '',
            email: '',
            message: '',
            captcha: ''
        })

        if (recaptchaRef.current)
            recaptchaRef.current.reset();
    }

    function submitContactForm(event: FormEvent) {
        // PreventDefault to avoid page reloading
        event.preventDefault();

        setcontactFormTouched({
            name: true,
            email: true,
            message: true
        })

        // Don't fetch php if form isn't valid
        if (!contactFormErrors.name.valid || !contactFormErrors.email.valid || !contactFormErrors.message.valid) {
            toaster('The form is not valid. The message was not sent.')
            return;
        }

        // Create options for fetch request
        const requestOptions = {
            method: 'POST',
            headers: new Headers({'Content-Type': 'application/json'}),
            body: JSON.stringify(contactFormValues)
        };

        // Reset captcha because it can only be validated once and can't be used afterwards.
        if (recaptchaRef.current)
            recaptchaRef.current.reset();

        fetch('/api/mail.php', requestOptions)
            .then((response) => {
                // Check if response is OK and json or send to catch.
                const contentType = response.headers.get('content-type');
                if (response.ok && contentType && contentType.indexOf('application/json') !== -1) {
                    return response;
                }
                return Promise.reject(response);
            })
            .then(response => {
                // Mail sent
                return response.json().then(data => {
                    console.log('json', data)

                    if (data.sent === true) {
                        toaster('The message has been sent successfully.', true)

                        resetForm()
                    }
                });
            })
            .catch(error => {
                // Mail not sent
                // If json it's an expected error
                return (error.json() as Promise<any>).then(data => {
                    console.log('error json', data)

                    toaster('The message was not sent : ' + data.message)
                }).catch(errorParseJson => toaster('An unexpected error occurred.'));
            })
    }

    function toaster(message: string, success = false): void {
        if (success)
            toast.success(message)
        else
            toast.error(message)
    }

    return (
        <>
            <form className="contact-form" onSubmit={submitContactForm}>
                <div className="contact-form-top">
                    <Input name="name"
                           labelPlaceholder="Name"
                           helperText={getErrorMessage('name')}
                           helperColor="error"
                           value={contactFormValues.name}
                           onChange={handleFormChange}
                           onBlur={handleFormBlur}
                    />
                    <Input
                        name="email"
                        labelPlaceholder="Email"
                        helperText={getErrorMessage('email')}
                        helperColor="error"
                        value={contactFormValues.email}
                        onChange={handleFormChange}
                        onBlur={handleFormBlur}
                    />
                </div>
                <Textarea
                    name="message"
                    labelPlaceholder="Message"
                    minRows={5}
                    helperText={getErrorMessage('message')}
                    helperColor="error"
                    value={contactFormValues.message}
                    onChange={handleFormChange}
                    onBlur={handleFormBlur}
                />
                <ReCAPTCHA ref={recaptchaRef} sitekey="6Lc4iKYhAAAAAAi--nXTEAG4gUHkTUaol9RovPeq" onChange={handleRecaptchaChange}/>
                <Button type="submit">Send</Button>
            </form>
        </>
    );
}

export default Contact;
